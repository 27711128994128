/* src/style/EditableNotebook.css */
.enb-wrapper {
  padding: 2rem;
  height: 100%; /* Full viewport height */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%; /* Take full width */
}

.enb-editable-notebook {
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0; /* Required for Firefox */
  position: relative; /* Add this for relative positioning of children */
}

.enb-notebook-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
}

.enb-notebook-button {
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  background-color: #f3f3f3;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 4px;
}

.enb-copy-button {
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  background-color: #f3f3f3;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 4px;
}

.enb-copy-tooltip {
  display: inline-block;
  margin-left: 0.5rem;
  color: green;
  font-weight: bold;
}

/* Make the MDXEditor content area scrollable */
.enb-editable-notebook > div[contenteditable="true"] {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  min-height: 0; /* Required for Firefox */
}

.enb-save-status {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 11px;
  color: #666;
  text-transform: capitalize;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.enb-save-status:first-letter {
  text-transform: capitalize;
}

.enb-editable-notebook {
  white-space: pre-wrap;
}

/* Add double spacing to the editor content */
.enb-mdx-editor {
  line-height: 1.5;
  overflow-y: hidden;
  list-style-position: inside;
}

.mdxeditor-diff-source-wrapper {
  height: calc(100% - 40px);
}

.enb-mdx-editor p {
  line-height: 2rem;
}

.enb-mdx-editor h1,
.enb-mdx-editor h2,
.enb-mdx-editor h3,
.enb-mdx-editor h4,
.enb-mdx-editor h5,
.enb-mdx-editor h6 {
  margin: 1.5em 0 1em;
  line-height: 1.5;
}

.enb-mdx-editor ul,
.enb-mdx-editor ol {
  line-height: 1.5;
  margin: 1em 0;
}

.enb-mdx-editor li {
  margin: 0.5em 0;
}

.enb-mdx-editor pre {
  margin: 1em 0;
  padding: 16px;
  background-color: var(--color-gray-light);
  border-radius: 6px;
  overflow-x: auto;
}

.enb-mdx-editor pre,
.enb-mdx-editor pre code,
.enb-mdx-editor code,
.enb-mdx-editor .cm-content {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace !important;
  font-size: 0.85em;
  line-height: 1.5;
  tab-size: 4;
}

/* Ensure code blocks maintain monospace font */
.enb-mdx-editor .cm-line,
.enb-mdx-editor .cm-line span {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace !important;
}

.mdxeditor-rich-text-editor {
  height: 100%;
}

.mdxeditor-root-contenteditable,
.mdxeditor-diff-editor {
  height: 100%;
  overflow-y: auto;
}

/* Custom scrollbar styling */
.enb-mdx-editor *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.enb-mdx-editor *::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.enb-mdx-editor *::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.enb-mdx-editor *::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Firefox scrollbar styling */
.enb-mdx-editor * {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

/* Make source mode scrollable */
.mdxeditor-source-editor {
  height: 100%;
  overflow-y: auto;
}

/* Ensure the wrapper for source editor takes full height */
.mdxeditor-source-editor-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Style the source mode textarea */
.mdxeditor-source-editor textarea {
  height: 100% !important;
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  border: none;
  background: transparent;
  resize: none;
}

/* Apply consistent scrollbar styling to source mode */
.mdxeditor-source-editor textarea::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.mdxeditor-source-editor textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.mdxeditor-source-editor textarea::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.mdxeditor-source-editor textarea::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Firefox scrollbar styling for source mode */
.mdxeditor-source-editor textarea {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

/* Fix for bullet point lists */
.enb-mdx-editor ul {
  list-style-type: disc;
  padding-left: 2em;
  margin: 1em 0;
}

.enb-mdx-editor ol {
  list-style-type: decimal;
  padding-left: 2em;
  margin: 1em 0;
}

.enb-mdx-editor li {
  display: list-item;
  margin: 0.5em 0;
}

/* Override any conflicting styles */
.mdxeditor-root-contenteditable ul,
.mdxeditor-root-contenteditable ol {
  list-style-position: outside !important;
}

/* Add proper spacing between list items */
.mdxeditor-root-contenteditable li p {
  margin: 0;
}

/* Ensure proper indentation for nested lists */
.mdxeditor-root-contenteditable li > ul,
.mdxeditor-root-contenteditable li > ol {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
