.singlefile-popup {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  padding: 12px 16px;
  z-index: 1000;
  min-width: 300px;
  max-width: 600px;
  width: auto;
  transition: all 0.2s ease;
  gap: 10px;
}

.singlefile-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex: 1;
  flex-direction: row;
}

.singlefile-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 1;
  overflow: hidden;
  gap: 8px;
}

.singlefile-actions {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
}

.singlefile-files {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.singlefile-counter {
  font-size: 12px;
  color: var(--color-text-light);
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2px 6px;
  border-radius: 12px;
  white-space: nowrap;
  flex-shrink: 0;
}

.singlefile-filename {
  font-size: 15px;
  color: #333;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.singlefile-filename.clickable {
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: underline;
  font-weight: 600;
}

.singlefile-filename.clickable:hover {
  color: var(--color-primary-dark);
}

.singlefile-ellipsis {
  color: var(--color-text);
  font-weight: normal;
}

.singlefile-button {
  padding: 3px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
  height: 24px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-width: 70px;
  text-transform: lowercase;
}

.singlefile-button.reject {
  background: none;
  color: #666;
  opacity: 0.7;
}

.singlefile-button.reject:hover {
  opacity: 1;
}

.singlefile-button.accept {
  background-color: var(--color-primary, #3b82f6);
  font-weight: var(--font-weight-medium);
}

.singlefile-button.accept:hover {
  opacity: 0.8;
}

.singlefile-nav-button {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  min-width: 24px;
  min-height: 24px;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.singlefile-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}

.singlefile-nav-button svg {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}

.singlefile-nav-filename {
  font-size: 13px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.singlefile-nav-button.left .singlefile-nav-filename {
  margin-left: 6px;
}

.singlefile-nav-button.right .singlefile-nav-filename {
  margin-right: 6px;
}

.singlefile-button-wrapper {
  position: relative;
  display: inline-block;
}

.singlefile-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: var(--color-gray-dark);
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
  margin-bottom: 4px;
}

.singlefile-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: var(--color-gray-dark) transparent transparent transparent;
}
