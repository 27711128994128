.chat-messages-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-gray-extra-light);
  max-width: none;
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  max-height: none;
  /* Account for ChatBar and max ChatInput height */
  min-height: 0;
  /* Crucial for Firefox flex overflow */
  z-index: 0;
  flex: 1;
}

.chat-messages-container::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.chat-messages-list {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: var(--color-gray-extra-light);
  max-width: none;
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.chat-messages-list::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.message {
  display: flex;
  width: 100%;
  margin: 8px 0;
  padding: 0;
  box-sizing: border-box;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ai-message {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
}

.ai-logo {
  display: none;
}

.message-content {
  flex: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 8px;
  white-space: normal;
  max-width: 100%;
  /* padding: 0 10px; */
}

/* Link and Image Styling */
.chat-link {
  color: var(--color-secondary);
  text-decoration: none;
  transition: color 0.2s ease;
  line-height: inherit;
}

.chat-link:hover {
  color: var(--color-secondary);
  text-decoration: underline;
}

.chat-image {
  max-width: 100%;
  border-radius: 8px;
  margin: 0.5em 0;
  display: block;
}

/* Markdown Styling */
.message-content > div {
  margin: 0;
  display: block;
  white-space: pre-wrap;
}

/* Heading Styles */
.chat-heading-1,
.chat-heading-2,
.chat-heading-3,
.chat-heading-4,
.chat-heading-5,
.chat-heading-6 {
  margin: 1em 0 0.5em;
  font-weight: 600;
  line-height: 1.25;
  color: var(--color-text);
}

.chat-heading-1 {
  font-size: 2em;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 0.3em;
}

.chat-heading-2 {
  font-size: 1.5em;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 0.3em;
}

.chat-heading-3 {
  font-size: 1.25em;
}

.chat-heading-4 {
  font-size: 1em;
}

.chat-heading-5 {
  font-size: 0.875em;
}

.chat-heading-6 {
  font-size: 0.85em;
  color: var(--color-text-light);
}

/* First heading in a message should not have top margin */
.message-content > div > .chat-heading-1:first-child,
.message-content > div > .chat-heading-2:first-child,
.message-content > div > .chat-heading-3:first-child,
.message-content > div > .chat-heading-4:first-child,
.message-content > div > .chat-heading-5:first-child,
.message-content > div > .chat-heading-6:first-child {
  margin-top: 0;
}

/* Update message content paragraph styling */
.message-content p {
  margin: 0.5em 0;
  display: block;
  white-space: pre-wrap;
}

/* Add specific handling for lists */
.message-content p + ul,
.message-content p + ol {
  margin-top: 0; /* Remove top margin when list follows paragraph */
}

.message-content ul,
.message-content ol {
  white-space: normal; /* Reset whitespace handling for lists */
  margin: 0.5em 0;
  padding-left: 2em;
}

/* Ensure list items don't have extra spacing */
.message-content li {
  white-space: normal;
  margin: 0;
}

.message-content pre {
  background-color: var(--color-gray-light);
  border-radius: 6px;
  padding: 16px;
  overflow-x: auto;
  font-size: 85%;
}

.message-content code {
  background-color: var(--color-gray-light);
  border-radius: 6px;
  padding: 0.2em 0.4em;
  font-size: 85%;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
}

.message-content pre code {
  background-color: transparent;
  padding: 0;
}

.message-content table {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
}

.message-content th,
.message-content td {
  border: 1px solid var(--color-border);
  padding: 6px 13px;
}

.message-content th {
  background-color: var(--color-gray-light);
}

.message-content blockquote {
  border-left: 3px solid var(--color-border);
  margin: 0.5em 0;
  padding-left: 1em;
  color: var(--color-text-light);
}

.user-message {
  justify-content: flex-end;
  margin-top: 16px;
  /* padding-left: 16px !important;
  padding-right: 16px !important; */
}
.user-message .message-content {
  color: var(--color-text);
  padding: 4px 4px;
  background-color: var(--color-gray-light);
  border-radius: 4px;
  margin-left: auto;
  width: fit-content;
  max-width: 100%;
  border: 1px solid var(--color-gray-medium);
}

.ai-message .message-content {
  max-width: 100%;
  color: var(--color-text);
  /* padding-left: 10px;
  padding-right: 10px; */
}

.loading-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.loading-animation {
  display: flex;
  gap: 4px;
}

.loading-animation span {
  width: 8px;
  height: 8px;
  background-color: var(--color-text-light);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.loading-animation span:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-animation span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.stream-progress {
  margin-left: 4px;
  margin-top: 8px;
  margin-bottom: 12px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 14px;
  color: var(--color-text);
}

.empty-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 20px;
}

.empty-chat-container h1 {
  font-size: 24px;
  margin: 16px 0 8px;
}

.empty-chat-container h3 {
  font-size: 15px;
  font-weight: normal;
  color: var(--color-text-light);
  margin: 0 0 32px;
}

.large-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 280px;
}

.large-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 12px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background: var(--color-background);
  cursor: pointer;
  font-size: 14px;
  color: var(--color-text);
  transition: all 0.2s ease;
}

.large-button:hover {
  background: var(--color-gray-light);
  border-color: var(--color-border);
}

.button-icon {
  width: 20px;
  height: 20px;
}

.file-tag {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: var(--color-gray-light);
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.85em;
  color: var(--color-text);
  margin: 0 2px;
  white-space: nowrap;
  user-select: none;
  vertical-align: baseline;
}

.message-content .file-tag,
.streamed-content .file-tag,
.message-content span.file-tag {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: var(--color-gray-medium-light) !important;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.85em;
  color: var(--color-text);
  margin: 0 2px;
  white-space: nowrap;
  user-select: none;
  vertical-align: baseline;
}

.empty-chat-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color-text-light);
  font-size: 14px;
  font-style: italic;
  padding: 20px;
  text-align: center;
}

@media screen and (min-width: 1280px) {
  .message {
    max-width: none;
    margin: 20px 0;
  }
}

.chat-messages-list > li:first-child {
  margin-top: 8px;
}

/* Ensure the file tags are properly rendered in streamed content */
.streamed-content .file-tag {
  display: inline-flex !important;
  align-items: center !important;
  gap: 6px !important;
  background-color: var(--color-gray-medium-light) !important;
  padding: 4px 8px !important;
  border-radius: 16px !important;
  font-size: 0.85em !important;
  color: var(--color-text) !important;
  margin: 0 2px !important;
  cursor: default !important;
  caret-color: transparent !important;
  white-space: nowrap !important;
  user-select: none !important;
}

/* Update existing file tag styles */
.message-content span.file-tag {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: var(--color-gray-light);
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.85em;
  color: var(--color-text);
  margin: 0 2px;
  white-space: nowrap;
  user-select: none;
}

/* Remove any markdown-specific styling that might interfere */
.message-content p {
  margin: 0.5em 0;
  display: block;
}

Ensure paragraphs in message content stay .message-content p {
  display: inline;
  margin: 0;
  padding: 0;
}

.status-message {
  color: var(--color-text);
  padding: 4px 8px;
  background-color: var(--color-gray-light);
  border-radius: 4px;
  display: inline-block;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.status-message::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: ribbon 2s infinite ease-in-out;
}

@keyframes ribbon {
  0% {
    left: -100%;
  }
  100% {
    left: 200%;
  }
}

.streamed-content-wrapper {
  display: block;
  gap: 0;
}

.stream-progress-below {
  margin-top: 8px;
}

.status-message-below {
  color: #666;
  padding: 4px 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: inline-block;
  margin-top: 4px;
}

/* Update existing status message styles */
.status-message {
  color: var(--color-text);
  padding: 4px 8px;
  background-color: var(--color-gray-light);
  border-radius: 4px;
  display: inline-block;
  max-width: 100%;
}

.stream-progress {
  margin-bottom: 12px;
}

/* Add these new styles */
.file-block-container {
  margin: 0.5em 0px;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  overflow: hidden;
}

.file-block-header {
  background-color: var(--color-gray-light);
  padding: 4px 8px;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  font-size: 0.72em;
  border-bottom: 1px solid var(--color-border);
  color: var(--color-text);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-block-filename {
  flex: 1;
}

.file-block-actions {
  display: flex;
  gap: 4px;
}

.file-action-button {
  padding: 2px 4px;
  height: 20px;
  line-height: 16px;
}

.file-action-button.reject {
  color: var(--color-text);
  opacity: 0.7;
}

.file-action-button.reject:hover {
  opacity: 1;
  background-color: transparent;
}

.file-action-button.accept {
  color: var(--color-primary);
}

.file-action-button.accept:hover {
  background-color: var(--color-primary);
  opacity: 0.8;
  color: var(--color-text);
}

.file-action-button svg {
  width: 14px;
  height: 14px;
}

.file-block-content {
  padding: 12px;
  background-color: var(--color-background);
  max-height: 400px;
  overflow-y: auto;
}

.file-block-content pre {
  margin: 0;
  padding: 0;
  background-color: transparent;
  white-space: pre;
  overflow-x: auto;
}

.file-block-content code {
  background-color: transparent;
  padding: 0;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  font-size: 0.9em;
  line-height: 1.5;
  tab-size: 4;
  color: var(--color-text);
  display: block;
}

/* Remove markdown-specific styling */
.file-block-content .chat-heading-1,
.file-block-content .chat-heading-2,
.file-block-content .chat-heading-3,
.file-block-content .chat-heading-4,
.file-block-content .chat-heading-5,
.file-block-content .chat-heading-6 {
  margin: 0;
  padding: 0;
  border: none;
  font-size: inherit;
  font-weight: normal;
  color: inherit;
}

.file-block-content p,
.file-block-content blockquote,
.file-block-content ul,
.file-block-content ol {
  margin: 0;
  padding: 0;
}

/* Remove any remaining markdown styling */
.file-block-content .chat-link,
.file-block-content .chat-image {
  color: inherit;
  text-decoration: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  max-width: none;
  display: inline;
}

/* Add scrollbar styling for the file block content */
.file-block-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.file-block-content::-webkit-scrollbar-track {
  background: var(--color-gray-extra-light);
}

.file-block-content::-webkit-scrollbar-thumb {
  background: var(--color-gray-medium);
  border-radius: 4px;
}

.file-block-content::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-medium-dark);
}

/* Add streaming styles */
.file-block-container.streaming {
  border-style: dashed;
}

.file-block-container.streaming .file-block-content {
  min-height: 50px;
  position: relative;
}

.file-block-container.streaming .file-block-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(
    to right,
    var(--color-primary) 30%,
    transparent 30%
  );
  background-size: 300% 100%;
  animation: shimmer 2s infinite;
  opacity: 0.5;
}

@keyframes shimmer {
  0% {
    background-position: right -100% top 0;
  }
  100% {
    background-position: left -100% top 0;
  }
}

/* Update streamed content styles to match regular messages */
.streamed-content {
  white-space: normal;
}

.streamed-content .message-text {
  display: inline;
}

/* Update ReactMarkdown container styles to be consistent */
.message-content span,
.streamed-content span {
  display: inline;
}

/* Ensure paragraphs in both message content and streamed content behave the same */
.message-content p,
.streamed-content p {
  display: inline;
  margin: 0;
  padding: 0;
}

/* Remove any extra spacing in streamed content */
.streamed-content-wrapper {
  display: block;
  gap: 0;
}

/* Ensure code blocks and pre tags are consistent */
.message-content pre,
.streamed-content pre {
  display: block;
  margin: 0.5em 0;
  white-space: pre;
}

.message-content code,
.streamed-content code {
  white-space: pre;
}

/* Ensure lists are displayed consistently */
.message-content ul,
.message-content ol,
.streamed-content ul,
.streamed-content ol {
  display: block;
  margin: 0.3em 0;
  padding-left: 1.2em;
  list-style-position: outside;
}

.message-content ul,
.streamed-content ul {
  list-style-type: none;
}

.message-content ul li,
.streamed-content ul li {
  position: relative;
  padding-left: 1em;
  margin: 0;
  line-height: 1.4;
}

.message-content ul li::before,
.streamed-content ul li::before {
  content: "–";
  position: absolute;
  left: 0;
  color: var(--color-text);
}

/* Fix nested list styling to prevent text overlap */
.message-content ul ul,
.message-content ol ul,
.streamed-content ul ul,
.streamed-content ol ul {
  margin: 0;
  padding-left: 1.5em; /* Increase padding for nested lists */
}

.message-content ul ul li,
.message-content ol ul li,
.streamed-content ul ul li,
.streamed-content ol ul li {
  padding-left: 1em;
}

.message-content ul ul li::before,
.message-content ol ul li::before,
.streamed-content ul ul li::before,
.streamed-content ol ul li::before {
  content: "-";
  left: 0;
}

/* Remove margin from first/last list items */
.message-content li:first-child,
.streamed-content li:first-child {
  margin-top: 0;
}

.message-content li:last-child,
.streamed-content li:last-child {
  margin-bottom: 0;
}

/* Update error message styles */
.error-message-container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 8px;
  background-color: var(--color-gray-extra-light);
  z-index: 10;
  margin-top: 8px;
}

.error-message-content {
  background-color: #fee2e2; /* Lighter red background */
  border: 1px solid #ef4444; /* Brighter red border */
  border-radius: 6px;
  padding: 12px;
  color: #991b1b;
  font-size: 14px;
  text-align: center;
}

.tool-call-block {
  margin: 4px 0;
}

.tool-call-summary {
  display: inline-block;
  background-color: transparent;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  color: var(--color-text);
  position: relative;
  cursor: pointer;
  border: 1px solid rgba(217, 61, 234, 0.3);
}

.tool-call-details {
  display: none;
  background-color: var(--color-background);
  border: 1px solid rgba(217, 61, 234, 0.3);
  border-radius: 6px;
  padding: 8px;
  margin-top: 8px;
  width: 100%;
  box-sizing: border-box;
}

/* Show details when visible class is present */
.tool-call-details.visible {
  display: block;
}

.tool-call-details pre {
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
}

.tool-call-details code {
  white-space: pre-wrap;
  font-size: 0.85em;
}

.file-block-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-circle {
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-gray-medium);
  border-top: 2px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.tool-call-toggle {
  background: none;
  border: none;
  color: var(--color-text);
  padding: 4px 8px;
  cursor: pointer;
  font-size: 0.7em;
  opacity: 0.6;
  transition: all 0.2s ease;
  text-transform: lowercase;
}

.tool-call-toggle:hover {
  opacity: 0.8;
}

.tool-call-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}

.tool-call-loading .spinner-circle {
  width: 24px;
  height: 24px;
  border: 2px solid var(--color-gray-medium);
  border-top: 2px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Update existing spinner animation if needed */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add this new style */
.chat-superscript {
  font-size: 0.5em;
  vertical-align: super;
  line-height: 0;
}

.caret-toggle {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

.caret-toggle:hover {
  opacity: 0.8;
}

/* Update the file block header font size */
.file-block-header {
  background-color: var(--color-gray-light);
  padding: 4px 8px;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  font-size: 0.72em;
  border-bottom: 1px solid var(--color-border);
  color: var(--color-text);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Update the action button font sizes */
.file-action-text-button {
  font-size: 0.72em;
  padding: 2px 4px;
}

/* Update caret icon size */
.caret-toggle svg {
  width: 12px;
  height: 12px;
}

/* Add this one new class */
.stream-progress-top {
  /* Remove this class */
}

.chat-messages-stop-button {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.chat-messages-stop {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 8px 6px 4px;
  border: 0.8px solid var(--light-text-dark);
  border-radius: 4px;
  background: transparent;
  color: var(--light-text-dark);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.chat-messages-stop:hover {
  background: rgba(0, 0, 0, 0.05);
}

.chat-messages-stop-icon {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  opacity: 0.9;
}

.chat-messages-shortcut {
  font-size: 12px;
  margin-right: -2px;
  color: var(--light-text-dark);
}

/* Remove the Apple icon styles since we're using the shortcut span */
