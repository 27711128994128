/* Base container styles */
.chatinput-form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 3rem;
  flex-grow: 1;
  width: 100%;
  gap: 8px;
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  padding-left: 4px;
  box-sizing: border-box;
  margin: 0;
  z-index: 1;
  overflow: visible;
  /* max-height: calc(40vh - 20px); */
}

.chatinput-main-bottom {
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  background-color: var(--color-gray-extra-light);
}

.chatinput-container {
  flex: 0 0 auto;
  width: 100%;
  margin: 0 !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}

/* Top Bar */
.chatinput-top-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  /* padding: 8px; */
  min-height: 24px;
  overflow: visible;
}

.chatinput-context-pill {
  position: relative;
  overflow: visible;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: var(--color-gray-light);
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.7em;
  color: var(--color-text);
  max-width: 200px;
  margin: 2px 0;
}

.chatinput-context-pill-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.chatinput-context-pill-tooltip {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-background);
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.85em;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0 2px 8px var(--color-shadow-medium);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.chatinput-context-pill:hover .chatinput-context-pill-tooltip {
  opacity: 1;
}

.chatinput-context-pill .chat-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatinput-context-pill .chatinput-close-icon {
  cursor: pointer;
  font-size: 0.75rem;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.chatinput-context-pill .chatinput-close-icon:hover {
  opacity: 1;
}

.chatinput-pill-icon {
  display: inline-block;
  margin-right: 4px;
}

/* Middle Input Area */
.chatinput-middle {
  background: transparent;
  /* padding: 8px; */
  min-height: 48px;
  text-align: left;
}

.chatinput-input {
  width: 100%;
  height: 100%;
  /* max-height: 40vh; */
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  white-space: pre-wrap;
  word-break: break-word;
  background: transparent;
  padding: 4px 8px;
  line-height: 1.5rem;
  border: none;
  outline: none;
  color: var(--color-text);
  font-size: 0.95rem;
  text-align: left;
  min-height: 48px;
}

.chatinput-input::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.chatinput-input:empty:before {
  content: attr(data-placeholder);
  color: var(--color-text-light);
  opacity: 0.7;
  pointer-events: none;
  font-size: 0.95rem;
}

.chatinput-input:focus:empty:before {
  opacity: 0.4;
}

/* Bottom Controls */
.chatinput-controls {
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 4px;
  padding-bottom: 4px;
  width: 100%;
  justify-content: space-between;
}

.chatinput-dropdown {
  background: transparent;
  color: var(--color-text);
  padding: 4px 8px;
  font-size: 0.75rem;
  cursor: pointer;
  min-width: 180px;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: left 8px center;
  background-size: 8px auto;
  padding-left: 24px;
  user-select: none;
}

.chatinput-dropdown:hover {
  opacity: 0.8;
}

/* Tooltip Styles */
.chatinput-tooltip-popper {
  position: fixed;
  z-index: 9999;
  background-color: var(--color-background);
  border: 1px solid var(--color-gray-medium);
  border-radius: 8px;
  box-shadow: 0 2px 8px var(--color-shadow-medium);
  min-width: 200px;
  max-width: 300px;
  overflow: hidden;
  display: block;
  background: white;
  font-size: 0.8rem;
}

.chatinput-tooltip-option {
  padding: 6px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 6px;
}

.chatinput-tooltip-option:hover {
  background-color: var(--color-hover-overlay);
}

.chatinput-tooltip-option.selected {
  background-color: var(--color-background-transparent);
  color: var(--color-text);
}

/* Model Dropdown Menu */
.model-dropdown {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 0;
  background: white;
  border: 1px solid var(--color-gray-medium);
  border-radius: 8px;
  box-shadow: 0 2px 8px var(--color-shadow-medium);
  min-width: 200px;
  max-width: 300px;
  overflow: hidden;
  z-index: 9999;
  font-size: 0.75rem;
}

.model-dropdown .chatinput-tooltip-option {
  padding: 6px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.model-dropdown .chatinput-tooltip-option:hover {
  background-color: var(--color-hover-overlay);
}

.model-dropdown .chatinput-tooltip-option.selected {
  background-color: var(--color-button-active-background);
  color: var(--color-button-active-text);
}

/* Disabled option style */
.model-dropdown .chatinput-option-disabled {
  opacity: 0.6;
  cursor: not-allowed;
  position: relative;
}

/* Coming soon tooltip */
.chatinput-coming-soon-tooltip {
  position: absolute;
  background-color: var(--color-tooltip-background);
  color: var(--color-tooltip-text);
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.chatinput-coming-soon-tooltip::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px 5px 0;
  border-style: solid;
  border-color: var(--color-tooltip-background) transparent transparent;
}

/* File Tag Styles */
.file-tag {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: var(--color-gray-light);
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.85em;
  color: var(--color-text);
  margin: 0 2px;
  cursor: default;
  caret-color: transparent;
}

.chatinput-file-tag {
  background-color: var(--color-secondary);
  color: var(--color-text);
  padding: 2px 6px;
  border-radius: 12px;
  margin: 0 2px;
  display: inline-flex;
  align-items: center;
  font-size: 0.9em;
  line-height: 1.2;
  cursor: default;
  caret-color: transparent;
}

.chatinput-error-message {
  background-color: var(--color-error);
  color: var(--color-text);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

/* Model Selector */
.chatinput-model-selector {
  position: relative;
  display: inline-block;
}

/* Submit Button */
.chatinput-submit-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: var(--color-secondary);
  color: var(--color-text);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  padding: 4px 10px;
  font-size: 0.75rem;
  transition: background-color 0.2s;
  margin-left: auto;
}

.chatinput-submit-button:hover {
  background-color: var(--color-secondary-hover);
}

.chatinput-submit-button:active {
  background-color: var(--color-secondary-active);
}

/* Add disabled state styling */
.chatinput-submit-button:disabled {
  background-color: var(--color-gray-medium);
  cursor: not-allowed;
  opacity: 0.6;
}

.chatinput-submit-button:disabled:hover {
  background-color: var(--color-gray-medium);
}

.chatinput-submit-button .chatinput-enter-key {
  font-size: 0.7rem;
  opacity: 0.8;
}

.chatinput-add-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: var(--color-hover-overlay);
  border: none;
  cursor: pointer;
  color: var(--color-text-light);
  font-size: 16px;
  padding: 0;
  margin: 0;
  transition: background-color 0.2s ease;
}

.chatinput-add-pill:hover {
  background-color: var(--color-hover-overlay);
}

/* AddFileTooltip Styles */
.addfile-tooltip {
  position: fixed;
  z-index: 9999;
  width: 260px;
  background: var(--color-background);
  border: 1px solid var(--color-gray-medium);
  border-radius: 8px;
  box-shadow: 0 4px 12px var(--color-shadow-medium);
  padding: 8px;
  font-size: 0.85rem;
}

/* Header with close button */
.addfile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

/* The search input */
.addfile-search {
  flex: 1;
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.9rem;
  margin-right: 8px;
}

/* Each named section */
.addfile-section {
  margin-bottom: 8px;
}

.addfile-section-title {
  font-weight: 600;
  margin-bottom: 4px;
}

/* A row for each file */
.addfile-row {
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.addfile-row:hover {
  background-color: var(--color-hover-overlay);
}

.addfile-icon-wrapper {
  width: 16px;
  text-align: center;
  flex-shrink: 0;
}

.addfile-name {
  flex: 1;
  font-size: 0.85rem;
  color: var(--light-text-dark);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.addfile-name-tooltip {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  background-color: var(--color-background);
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.85em;
  white-space: nowrap;
  z-index: 1001;
  box-shadow: 0 2px 8px var(--color-shadow-medium);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.addfile-name:hover .addfile-name-tooltip {
  opacity: 1;
}

/* If you want a separate "Remove" button in the row */
.addfile-remove {
  background: none;
  border: none;
  color: var(--color-text-light);
  font-size: 0.8rem;
  padding: 2px 6px;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
}

.addfile-remove:hover {
  background-color: var(--color-hover-overlay);
  color: var(--color-text-light);
}

/* If there's an empty state in "Added Files" */
.addfile-empty {
  font-style: italic;
  font-size: 0.8rem;
  color: var(--color-text-light);
  padding: 4px;
}

.chatinput-tooltip-icon {
  margin-right: 8px;
  display: inline-block;
}

.chatinput-pill-icon-wrapper {
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
  height: 16px; /* Adjust this value to match your text height */
}

.chatinput-pill-icon-wrapper img {
  position: relative;
  top: 1px; /* Changed from 2px to 1px */
  width: 14px;
  height: 14px;
}

/* Also update the tooltip icons for consistency */
.chatinput-tooltip-icon {
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
  height: 16px;
}

.chatinput-tooltip-icon img {
  position: relative;
  top: 1px; /* Changed from 2px to 1px */
  width: 14px;
  height: 14px;
}

/* Update tooltip icon styles */
.chatinput-tooltip-icon-wrapper {
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
  height: 16px;
  vertical-align: middle; /* Add this to align with text */
}

.chatinput-tooltip-icon-wrapper img {
  position: relative;
  top: 1px;
  width: 14px;
  height: 14px;
  vertical-align: middle; /* Add this to align with text */
}

.chatinput-edit-controls {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding: 2px 4px 2px 8px;
  border-bottom: 1px solid var(--color-gray-medium);
  height: 24px;
  align-items: center;
  width: 100%;
}

.chatinput-edit-button {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
  height: 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.chatinput-edit-button.reject {
  background: none;
  color: var(--color-text);
  opacity: 0.7;
}

.chatinput-edit-button.reject:hover {
  opacity: 1;
}

.chatinput-edit-button.accept {
  background-color: var(--color-primary);
  color: var(--color-text);
}

.chatinput-edit-button.accept:hover {
  background-color: var(--color-primary);
  opacity: 0.8;
}

/* Remove the old tooltip styles */
.chatinput-highlight-tooltip {
  position: absolute;
  bottom: calc(100% + 16px); /* Position above the pill with 16px gap */
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-background);
  border: 1px solid rgba(217, 61, 234, 0.3);
  border-radius: 6px;
  padding: 8px;
  min-width: 200px;
  max-width: 400px;
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.chatinput-highlight-tooltip pre {
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
}

.chatinput-highlight-tooltip code {
  white-space: pre-wrap;
  font-size: 0.85em;
  color: var(--color-text);
}

/* Update the arrow positioning */
.chatinput-highlight-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: var(--color-background) transparent transparent transparent;
  pointer-events: none;
}

/* Ensure the pill has proper positioning */
.highlight-pill {
  position: relative;
  cursor: default;
  z-index: 1;
  pointer-events: auto;
}

.chatinput-edit-button-wrapper {
  position: relative;
  display: inline-block;
}

.chatinput-edit-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: var(--color-gray-dark);
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
  margin-bottom: 4px;
}

.chatinput-edit-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: var(--color-gray-dark) transparent transparent transparent;
}
