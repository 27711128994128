.file-upload-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.file-upload-modal-dialog {
  background-color: var(--color-input-background);
  color: var(--color-text);
  border: 1px solid var(--color-border-transparent);
  padding: 32px;
  border-radius: 12px;
  max-width: 1000px;
  width: 95%;
  height: 60vh;
  min-height: 700px;
  max-height: 900px;
  text-align: center;
  box-shadow: 0 8px 30px var(--color-shadow-medium);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  z-index: 1101;
}

.file-upload-modal-dialog h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
}

.file-upload-modal-dialog p {
  margin-bottom: 16px;
  color: var(--color-text);
}

.dropzone {
  border: 2px dashed var(--color-input-border);
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 20px 0;
}

.dropzone p {
  font-size: var(--font-size-lg);
  margin: 0;
}

.dropzone:hover,
.file-list li:hover {
  background-color: var(--color-hover-overlay);
}

/* Remove hover effect for sequence input fields */
.sequence-input input:hover,
.sequence-input textarea:hover {
  background-color: transparent !important;
}

form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;
}

.file-list {
  max-height: none;
  overflow-y: visible;
  margin-bottom: 20px;
  color: black;
  text-align: left;
  overflow: visible;
}

.file-list h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.file-list li {
  display: table-row;
  /* Remove background-color */
}

.file-list li > div {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  border: 1px solid var(--color-input-border);
}

.file-list li > div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 70%;
}

.file-list li > div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.file-list li button {
  background-color: var(--color-error);
  color: var(--color-text);
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-sm);
  margin-left: auto;
  float: right;
}

.file-list li button:hover {
  background-color: var(--color-secondary);
}

.file-upload-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--color-input-border);
  background-color: var(--color-input-background);
  position: sticky;
  bottom: 0;
  /* z-index: 1; */
}

.file-upload-confirm-button,
.file-upload-cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-sm);
  transition: background-color 0.3s ease;
}

.file-upload-confirm-button {
  background-color: var(--color-secondary);
  color: var(--color-text);
}

.file-upload-confirm-button:hover {
  background-color: var(--color-secondary);
}

.file-upload-cancel-button {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
}

.file-upload-cancel-button:hover {
  background-color: var(--color-secondary);
}

.error-message {
  background-color: var(--color-error);
  border: 1px solid var(--color-secondary);
  color: var(--color-text);
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: var(--font-size-sm);
}

.error-message p {
  margin: 0;
}

.upload-type-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 20px;
  gap: 0;
}

.toggle-button {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
  border: none;
  padding: 10px 20px;
  border-radius: 0;
  cursor: pointer;
  font-size: var(--font-size-md);
  transition: background-color 0.3s ease;
  flex: 1;
  /* max-width: 220px; */
  white-space: nowrap;
}

.toggle-button.active {
  background-color: var(--color-secondary);
}

.toggle-button:hover:not(.active) {
  background-color: var(--color-secondary);
}

.toggle-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.toggle-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sequence-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  overflow: hidden;
}

.sequence-input input,
.sequence-input textarea {
  background-color: var(--color-input-background);
  color: var(--color-text);
  border: 1px solid var(--color-input-border);
  padding: 8px;
  border-radius: 4px;
  font-size: var(--font-size-sm);
  outline: none;
}

.sequence-input textarea {
  flex-grow: 1;
  resize: none;
  min-height: 100px;
}

/* Ensure minimum spacing for small screens */
@media (max-height: 500px) {
  .file-upload-modal-dialog {
    height: 90vh;
    min-height: 350px;
  }
}

.file-specs {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
}

.file-specs select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid var(--color-input-border);
  background-color: var(--color-input-background);
  color: var(--color-text);
  font-size: var(--font-size-sm);
}

.topology-select {
  width: 100px;
  background-color: transparent;
}

.parent-file-select {
  position: relative;
  flex: 1;
}

.parent-file-select__control {
  min-height: 32px;
  /* background-color: #DFDFDF; */
  border-color: #b9b9b9;
  min-height: 32px !important;
  height: 32px !important;
}

.parent-file-select__value-container {
  padding: 0 8px;
  height: 32px !important;
  padding: 0 8px !important;
}

.parent-file-select__input {
  margin: 0 !important;
}

.parent-file-select__indicators {
  height: 32px !important;
}

.parent-file-select__menu {
  background-color: var(--color-input-background) !important;
  color: var(--color-text) !important;
  position: absolute !important;
  width: 100% !important;
  box-shadow: 0 2px 5px var(--color-shadow-soft) !important;
}

.parent-file-select__option {
  background-color: var(--color-input-background) !important;
  color: var(--color-text) !important;
}

.parent-file-select__option--is-focused {
  background-color: var(--color-secondary) !important;
}

.parent-file-select__single-value {
  color: var(--color-text);
}

/* Styles for react-select dropdown */
.parent-file-select__menu,
.css-26l3qy-menu,
.css-1nmdiq5-menu {
  background-color: #dfdfdf !important;
  color: black !important;
  position: absolute !important;
  background-color: #dfdfdf !important;
  color: black !important;
  width: 100% !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
}

.parent-file-select__option,
.css-1n7v3ny-option,
.css-9gakcf-option {
  background-color: #dfdfdf !important;
  color: black !important;
  background-color: #dfdfdf !important;
  color: black !important;
}

.parent-file-select__option--is-focused,
.css-1n7v3ny-option:hover,
.css-9gakcf-option:hover {
  background-color: #f28a22 !important;
  background-color: #f28a22 !important;
}

.file-specs {
  position: relative;
}

/* New Styles for Header Row */
.file-list-header {
  background-color: var(--color-gray-medium);
  font-weight: var(--font-weight-bold);
  cursor: default;
}

.file-list-header div {
  padding: 10px;
  border: 1px solid #b9b9b9;
}

.file-list-header div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.file-list-header div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Adjust existing file-list styles if necessary */
.file-list li {
  display: table-row;
  /* Remove background-color */
}

.file-list li > div {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  border: 1px solid #b9b9b9;
}

.file-list li > div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 70%;
}

.file-list li > div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sequence-error {
  color: #c4002f;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
  padding: 5px 0;
}

.file-upload-confirm-button:disabled {
  background-color: #b9b9b9;
  cursor: not-allowed;
  opacity: 0.7;
}

.file-upload-confirm-button:disabled:hover {
  background-color: #b9b9b9;
}

/* Single, consistent styling for all radio buttons */
.file-upload-modal-dialog .file-upload-radio-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  /* margin: 20px 0; */
  padding: 10px;
  border-radius: 4px;
}

.file-upload-modal-dialog .file-upload-radio-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.file-upload-modal-dialog .file-upload-radio-group input[type="radio"] {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 18px !important;
  height: 18px !important;
  border: 2px solid var(--color-secondary) !important;
  border-radius: 50% !important;
  outline: none !important;
  cursor: pointer !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.file-upload-modal-dialog .file-upload-radio-group input[type="radio"]:checked {
  background-color: var(--color-secondary) !important;
  box-shadow: inset 0 0 0 3px var(--color-input-background) !important;
}

.file-upload-modal-dialog .annotation-info {
  font-style: italic;
  font-size: var(--font-size-sm);
  color: var(--color-text);
  text-align: center;
  margin: 10px 0;
  padding: 10px;
  background-color: var(--color-hover-overlay);
  border-radius: 4px;
}

/* Add specific styles for the docs upload section if needed */
.docs-upload-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  overflow: hidden;
}

.file-format-info {
  text-align: center;
  color: var(--color-text);
  font-size: var(--font-size-sm);
  margin-top: 8px;
  margin-bottom: 16px;
  font-style: italic;
}

/* Add these new styles */
.sequence-type-group {
  margin-bottom: 20px;
  background-color: var(--color-hover-overlay);
  padding: 15px;
  border-radius: 8px;
}

.sequence-type-group label {
  font-weight: var(--font-weight-medium);
}

/* Update existing radio group styles */
.file-upload-radio-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  border-radius: 4px;
}

.file-upload-radio-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.file-upload-radio-group label:hover {
  background-color: var(--color-hover-overlay);
}

/* Update sequence input placeholder styles */
.sequence-input textarea::placeholder {
  color: var(--color-text-muted);
  font-style: italic;
}

/* Add specific styles for sequence type validation */
.sequence-error {
  color: var(--color-error);
  font-size: var(--font-size-sm);
  margin-top: 5px;
  text-align: left;
  padding: 5px 10px;
  background-color: var(--color-error-background);
  border-radius: 4px;
}

/* Style for the sequence name error - red text only */
.sequence-name-error {
  color: var(--color-error);
  font-size: var(--font-size-sm);
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: left;
  padding: 0 2px;
}
