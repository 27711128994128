.pv-paper-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: var(--color-background);
  overflow: hidden;
}

.pv-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100%;
}

.pv-thumbnail-container {
  width: 180px;
  min-width: 180px;
  overflow-y: auto;
  background: var(--color-gray-extra-light);
  border-right: 1px solid var(--color-border);
  padding: 8px;
  height: 100%;
  position: sticky;
  top: 0;
}

.pv-thumbnail-container::-webkit-scrollbar {
  width: 6px;
}

.pv-thumbnail-container::-webkit-scrollbar-track {
  background: var(--color-gray-extra-light);
}

.pv-thumbnail-container::-webkit-scrollbar-thumb {
  background: var(--color-gray-medium);
  border-radius: 3px;
}

.pv-thumbnail {
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Add a new wrapper div for just the page part */
.pv-thumbnail-page-wrapper {
  border: 2px solid transparent;
  border-radius: 4px;
  transition: all 0.2s ease;
  background: var(--color-gray-extra-light);
  width: 100%;
}

.pv-thumbnail:hover .pv-thumbnail-page-wrapper {
  border-color: rgba(242, 138, 34, 0.5);
  background: var(--color-gray-light);
}

.pv-thumbnail.active .pv-thumbnail-page-wrapper {
  border-color: transparent;
  background: var(--color-gray-light);
  transform: scale(1.02);
}

.pv-thumbnail-number {
  position: relative;
  background: white;
  color: var(--color-text);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-top: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-weight: var(--font-weight-medium);
}

.pv-thumbnail.active .pv-thumbnail-number {
  background: white;
  color: var(--color-text);
}

.pv-document-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  height: 100%;
}

.pv-document-container::-webkit-scrollbar {
  width: 8px;
}

.pv-document-container::-webkit-scrollbar-track {
  background: var(--color-gray-extra-light);
}

.pv-document-container::-webkit-scrollbar-thumb {
  background: var(--color-gray-medium);
  border-radius: 4px;
}

.pv-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: var(--color-text);
}

.pv-error {
  color: var(--color-error);
  text-align: center;
  padding: 2rem;
}

.pv-zoom-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pv-control-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--color-gray-light);
  border: 1px solid var(--color-border);
  color: var(--color-text);
  transition: background-color 0.2s;
}

.pv-control-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pv-control-button:hover:not(:disabled) {
  background-color: var(--color-hover-overlay);
}

.pv-zoom-info {
  min-width: 60px;
  text-align: center;
  color: var(--color-text);
}

.pv-copy-button-container {
  position: relative;
  display: inline-block;
}

.pv-copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.pv-copy-button:hover {
  opacity: 1;
}

.pv-copy-icon {
  width: 24px;
  height: 24px;
}

.pv-copy-tooltip {
  position: absolute;
  background-color: var(--color-tooltip-background);
  color: var(--color-tooltip-text) !important;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  z-index: 1000;
  animation: tooltipFade 2s ease;
  left: 40px;
  top: 45px;
  white-space: nowrap;
}

@keyframes tooltipFade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* PDF container styling */
.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  position: relative;
}

/* Ensure canvas is below text layer */
.react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
  position: relative;
  z-index: 1;
}

/* Enhanced text layer styling */
.textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: all !important;
  user-select: text !important;
}

.textLayer > span {
  color: transparent !important;
  position: absolute;
  cursor: text;
  text-shadow: none !important;
}

.textLayer ::selection {
  background: rgba(255, 255, 0, 0.4) !important;
  mix-blend-mode: multiply;
}

.pv-main-document {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pv-page {
  margin-bottom: 20px;
  width: fit-content;
}

.pv-selected-text {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-background);
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pv-command-k-highlight {
  position: fixed;
  background-color: rgba(255, 255, 0, 0.4);
  pointer-events: none;
  z-index: 500;
  border-radius: 2px;
}

.pv-command-k-highlight.persistent {
  animation: highlightAppear 0.3s ease-out;
}

@keyframes highlightAppear {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Remove the pulse animation since we want it persistent */

/* Enhance the text selection style */
.textLayer ::selection {
  background: rgba(255, 255, 0, 0.4) !important;
  mix-blend-mode: multiply;
}

/* Add floating zoom controls styles */
.pv-zoom-controls-floating {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background: var(--color-gray-dark);
  border-radius: 8px;
  padding: 8px 10px;
  gap: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.2s ease;
  z-index: 100;
}

.pv-zoom-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.pv-zoom-button:hover {
  background-color: var(--color-gray-medium);
}

.pv-zoom-button img {
  width: 18px;
  height: 18px;
  opacity: 0.9;
}

.pv-zoom-level {
  color: white;
  font-size: 14px;
  min-width: 50px;
  text-align: center;
  user-select: none;
}

/* Remove old zoom control styles */
.pv-zoom-controls,
.pv-control-button,
.pv-zoom-info {
  display: none;
}

.artifact-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-loading-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.artifact-loading-overlay.visible {
  opacity: 1;
  pointer-events: auto;
}

.artifact-loading-spinner {
  width: 60px;
  height: 60px;
  border: 6px solid var(--color-gray-medium);
  border-top: 6px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hide the default loading messages */
.react-pdf__Page--loading {
  display: none !important;
}

.react-pdf__Document--loading {
  display: none !important;
}
